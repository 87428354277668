:root {
  --font-roboto: 'Roboto', sans-serif;
  --font-vidaloka: 'Vidaloka', serif;
}

body, html, #root {
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
}
